export enum RoleStatus {
  desativado = 0,
  leitor = 1,
  editor = 2,
}

export const roleStatus = [
  { key: 0, value: "Desativado" },
  { key: 1, value: "Leitor" },
  { key: 2, value: "Editor" },
];

export const roleStatusTasks = [
  { key: 0, value: "Desativado" },
  { key: 2, value: "Editor" },
];
